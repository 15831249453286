<template>
  <WsMain>
    <WsCrud
      :modelName="$store.state.stone_model[modelName].modelName"
      :label="$store.state.stone_model[modelName].label"
      :fields="_fields"
      :liveSearching="true"
      :showFields="showFields"
      :expandable="false"
      :showExpand="false"
      :labelInLocale="true"
      :infiniteScroll="true"
      :inRowBtnRead="false"
    ></WsCrud>
  </WsMain>
</template>

<script>
import S_App_Auth from "@/__vue2stone_cms/service/app/auth";
export default {
  metaInfo() {
    return {
      title: this.$store.state.stone_model[this.modelName].label,
    };
  },

  data: () => ({
    modelName: "service_store_noti",
    showFields: ["content", "start", "end", "service_stores"],
  }),

  computed: {
    _fields() {
      const _fields = this.$store.state.stone_model[this.modelName].fields;
      if (!S_App_Auth.hasScope(["boss"])) {
        delete _fields["admin_groups"];
        delete _fields["cmser_groups"];
      }
      return _fields;
    },
  },
};
</script>